import React, { useState, useEffect, useLayoutEffect } from "react";
import PropTypes from "prop-types";
import AOS from "aos";

import SEO from "./seo";
import Header from "./header";
import Footer from "../components/footer";

import ScrollDirection from "../utils/scroll-direction";

import "../styles/index.scss";

const HeaderHeight = 100;

const openedMobileMenuStyles = {
  position: "fixed",
  overflow: "hidden",
  overscrollBehavior: "none",
  maxHeight: "100vh",
  height: "100%",
  width: "100%"
};

if (typeof window !== "undefined") {
  // eslint-disable-next-line global-require
  require("smooth-scroll")('a[href*="#"]', { speed: 150 });
}

const Layout = ({
  hideAllHeader,
  hideHeader,
  isDirectoryPage,
  hideFooter,
  isHeaderDark,
  headerBgClass,
  footerBgClass,
  children,
  meta,
  ...props
}) => {
  let containerRef = React.createRef();
  const [toggleMobileMenu, setToggleMobileMenu] = useState(false);

  const preventTouch = e => {
    if (toggleMobileMenu) {
      e.preventDefault();
      e.returnValue = false;
      return false;
    }
  };

  /* useEffect(() => {
    AOS.init({ once: true });

    if (containerRef.current) {
      containerRef.current.addEventListener("touchmove", preventTouch, {
        passive: false
      });
    }

    return () => {
      if (containerRef.current) {
        containerRef.current.removeEventListener("touchmove", preventTouch, {
          passive: false
        });
      }
    };
  }, [toggleMobileMenu]); */

  const isOpenMobileMenu = state => {
    if (!state) {
      containerRef.current.removeEventListener("touchmove", preventTouch, {
        passive: false
      });
    }
    setToggleMobileMenu(state);
  };

  const getScrollDirection = ScrollDirection("down", HeaderHeight);
  const isScrollUp = getScrollDirection.direction === "up";
  const isTop = getScrollDirection.isTop;

  //track all external links in mixpanel
  //const mixpanel = useMixpanel();

 /*  useLayoutEffect(() => {
    setTimeout(() => {
      if (!document) return;
      const links = document.getElementsByTagName("a");

      const handler = e => {
        mixpanel.track("External link click", { Link: e.target.href });
      }; 

      Array.prototype.forEach.call(links, link => {
        if (
          link.href &&
          link.href.startsWith("http") &&
          !link.href.startsWith(window.location.origin)
        ) {
          link.addEventListener("click", handler);
        }
      });
    }, 500);
  }, []); */

  return (
    <div
      ref={containerRef}
      style={toggleMobileMenu ? openedMobileMenuStyles : {}}
    >
      <SEO meta={meta} {...props} />

      <Header
        hideHeader={hideHeader}
        isDirectoryPage={isDirectoryPage}
        isHeaderDark={isHeaderDark}
        headerBgClass={headerBgClass}
        isScrollUp={isScrollUp}
        isTop={isTop}
        isOpenMobileMenu={isOpenMobileMenu}
      />

      <div style={{ opacity: toggleMobileMenu ? 0 : 1 }}>
        <main style={{ marginTop: HeaderHeight }}>{children}</main>

        {!hideFooter && <Footer footerBgClass={footerBgClass} />}
      </div>
    </div>
  );
};

Layout.propTypes = {
  children: PropTypes.node
};

export default Layout;
