import React, { useState } from "react";
import { InstantSearch, Configure, PoweredBy } from "react-instantsearch-dom";
import algoliasearch from "algoliasearch/lite";
import SearchInput from "./SearchInput";
import { SearchResult } from "./SearchResults";
import CrossSvg from "../../images/icons/interface/cross.svg";

const searchClient = algoliasearch(
  process.env.GATSBY_ALGOLIA_APP_ID,
  process.env.GATSBY_ALGOLIA_API_KEY
);

const INDEX_NAME = "Blog posts";

export const SearchModal = ({ closeModal }) => {
  const [query, setQuery] = useState();

  return (
    <InstantSearch
      searchClient={searchClient}
      indexName={INDEX_NAME}
      hitsPerPage={5}
      onSearchStateChange={({ query }) => setQuery(query)}
    >
      <div
        style={{
          padding: "1.2rem 1.2rem 0.5rem 1.2rem",
          display: "grid",
          gridTemplateRows: "min-content 1fr min-content",
          height: "100%",
          width: "100%"
        }}
      >
        <Configure
          hitsPerPage={8}
          distinct
          attributesToSnippet={["content:20"]}
        />

        <div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              marginBottom: "1rem"
            }}
          >
            <h3
              style={{
                color: "#6C757D",
                fontWeight: 500,
                fontSize: "20px",
                lineHeight: "24px",
                marginBottom: 0
              }}
            >
              Search in our blog
            </h3>

            <button
              style={{
                backgroundColor: "white",
                fontWeight: 500,
                padding: "0.2rem 0.4rem",
                fontSize: "13px",
                lineHeight: "13px"
              }}
              onClick={() => closeModal()}
              className="btn btn-light"
            >
              <CrossSvg height={18} />
            </button>
          </div>
          <SearchInput />
        </div>

        <SearchResult indexName={INDEX_NAME} show={!!query} />

        <div
          style={{ fontSize: "12px", paddingTop: "14px" }}
          className="search-by-algolia"
        >
          <PoweredBy />
        </div>
      </div>
    </InstantSearch>
  );
};
