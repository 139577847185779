// useLocation.js
import { useEffect, useState } from "react";

export const useLocation = () => {
  const [location, setLocation] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    fetch('https://ipapi.co/json/?access_key=${process.env.IPAPI_ACCESS_KEY}')
      .then(response => response.json())
      .then(data => {
        setLocation(data.country_name);
        setIsLoading(false);
      })
      .catch(error => {
        console.log('Error: ', error);
        setIsLoading(false);
      });
  }, []);

  return { location, isLoading };
};