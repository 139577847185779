import { Link } from "gatsby";
import React from "react";
import {
  Highlight,
  Hits,
  Index,
  connectStateResults,
  Snippet
} from "react-instantsearch-dom";

const PageHit = ({ hit }) => {
  return (
    <Link to={"/blog/" + hit.slug}>
      <img alt={hit.title} src={hit.cardImageUrl} width="120px" height="98px" />

      <Highlight attribute="title" hit={hit} tagName="mark" />
      <Snippet attribute="content" hit={hit} tagName="mark" />

      <span className="hit-category">{hit.categoryName}</span>
    </Link>
  );
};

const Results = connectStateResults(
  ({ searchState, searchResults, children }) =>
    searchResults && searchResults.nbHits !== 0 ? (
      children
    ) : (
      <div className="no-results">No results for {searchState.query}.</div>
    )
);

const HitsInIndex = ({ indexName }) => (
  <Index indexName={indexName}>
    <Results>
      <Hits className="list-group" hitComponent={PageHit} />
    </Results>
  </Index>
);

export const SearchResult = ({ indexName, show }) => (
  <div
    className="search-results"
    style={{ display: "flex", height: "100%", flexDirection: "column" }}
  >
    <div style={{ display: show ? "block" : "none" }}>
      <div style={{ flexGrow: 1, flexBasis: "0px" }}>
        <HitsInIndex indexName={indexName} />
      </div>
    </div>
  </div>
);
