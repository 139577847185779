import React from "react";
import { connectSearchBox } from "react-instantsearch-dom";
import { SearchSvg } from "./SearchNavButton";

export default connectSearchBox(
  ({ refine, currentRefinement, className, onFocus }) => (
    <form className={"search-input-form " + (className || "")}>
      <div className="form-group">
        <div
          style={{
            position: "absolute",
            left: 0,
            top: 0,
            bottom: 0,
            marginLeft: "12px",
            marginTop: "17px",
            marginBottom: "19px",
            zIndex: 10,
            color: "#14213D"
          }}
        >
          <SearchSvg className="searchIcon" width="24" height="24" />
        </div>

        <div className="input-group">
          <input
            autoFocus
            style={{
              paddingLeft: "2.75rem",
              height: "60px"
            }}
            className="form-control"
            type="text"
            placeholder="When there’s a will, there’s UX"
            aria-label="Search"
            onChange={e => refine(e.target.value)}
            value={currentRefinement}
            onFocus={onFocus}
          />
        </div>
      </div>
    </form>
  )
);
