import React, { useState } from "react";
import Popup from "reactjs-popup";
import { SearchModal } from "./SearchModal";

export const SearchSvg = ({ className, ...props }) => (
  <svg
    className={className || ""}
    width="24"
    height="24"
    viewBox="0 0 32 32"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M19.0572 22.2761C18.5365 21.7554 18.5365 20.9112 19.0572 20.3905C19.5778 19.8698 20.4221 19.8698 20.9428 20.3905L26.2761 25.7239C26.7968 26.2446 26.7968 27.0888 26.2761 27.6095C25.7554 28.1302 24.9112 28.1302 24.3905 27.6095L19.0572 22.2761Z" />
    <path d="M14.6666 21.3333C18.3485 21.3333 21.3333 18.3485 21.3333 14.6666C21.3333 10.9847 18.3485 7.99992 14.6666 7.99992C10.9847 7.99992 7.99998 10.9847 7.99998 14.6666C7.99998 18.3485 10.9847 21.3333 14.6666 21.3333ZM14.6666 23.9999C9.51199 23.9999 5.33331 19.8212 5.33331 14.6666C5.33331 9.51193 9.51199 5.33325 14.6666 5.33325C19.8213 5.33325 24 9.51193 24 14.6666C24 19.8212 19.8213 23.9999 14.6666 23.9999Z" />
  </svg>
);

export const SearchNavButton = () => {
  const [isOpen, setIsOpen] = useState();

  return (
    <Popup
      overlayStyle={{
        backgroundColor: "rgb(20,33,61, 0.5)"
      }}
      trigger={
        <button className="btn search-nav-button nav-link">
          <SearchSvg />
        </button>
      }
      modal
      contentStyle={{ margin: "5% auto auto auto", height: "720px" }}
      lockScroll
      open={isOpen}
      onOpen={() => setIsOpen(true)}
      onClose={() => setIsOpen(false)}
    >
      <SearchModal closeModal={() => setIsOpen(false)} />
    </Popup>
  );
};
