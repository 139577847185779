import React, { useEffect } from 'react';

const CalendlyButton = ({bntClass = "", btnLabel = "Book a Call"}) => {
  
  useEffect(() => {
    // Load the Calendly script
    const script = document.createElement('script');
    script.src = 'https://assets.calendly.com/assets/external/widget.js';
    script.async = true;
    document.body.appendChild(script);

    // Remove the script when the component unmounts
    return () => {
      document.body.removeChild(script);
    };
  }, []);

  const handleClick = (e) => {
    e.preventDefault();
    // Initialize the Calendly popup widget
    Calendly.initPopupWidget({ url: 'https://calendly.com/adamfard/quick-chat-w?primary_color=2d63d7', branding: false });
    return false;
  };

  return (
    <>
      <link href="https://assets.calendly.com/assets/external/widget.css" rel="stylesheet" />
      <style>{`.calendly-overlay .calendly-popup {max-height: 690px;}; .jWSwi_R_Xl7kPjUhuQoo {display: hide !important} `}</style>
      <button id="freeconsultation" className={`btn btn-primary lead-cta ` + bntClass} onClick={handleClick}>{btnLabel}</button>
    </>
  );
};

export default CalendlyButton;
